import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'tech-menu-list',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './menu-list.component.html',
  styleUrl: './menu-list.component.scss',
})
export class MenuListComponent {
  @Input() children: any[] = [
    {
      icon: '',
      label: '',
      type: '',
      routerLink: '',
      onClick: () => {},
    },
  ];
  @ViewChild('boxPrimary') boxPrimary!: ElementRef<HTMLDivElement>;

  public isVisible: boolean = false;
  public coorY: number = 0;
  public coorX: number = 0;

  @HostListener('document:click', ['$event'])
  onCLick(event: any) {
    if (
      !this.boxPrimary.nativeElement.contains(event.target) &&
      this.isVisible
    ) {
      this.toggleVisibility();
    }
  }

  toggleVisibility() {
    if (!this.isVisible) {
      this.buildCoor();
    }

    this.isVisible = !this.isVisible;
  }

  buildCoor() {
    const { x, y, width, height } =
      this.boxPrimary.nativeElement.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;

    if (x + 150 > innerWidth) {
      this.coorX = 150 * -1;
    } else {
      this.coorX = 0;
    }

    if (y + 150 > innerHeight) {
      this.coorY = height * -1;
    } else {
      this.coorY = height + 8;
    }
  }
}

import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private keyOfToken: string = 'x-bearer-tech';
  private _localStorageSrc: LocalStorageService = inject(LocalStorageService);

  setToken(token: string) {
    this._localStorageSrc.set(this.keyOfToken, token);
  }

  getToken(): string | null {
    return this._localStorageSrc.get(this.keyOfToken);
  }

  getInfo(): any | null {
    try {
      const token = this.getToken();
      if (!token) throw {};

      const tokenHashed = token
        .split('.')[1]
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const token_clean = decodeURIComponent(
        atob(tokenHashed)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(token_clean);
    } catch (error) {
      return null;
    }
  }

  haveAnyPermission(...permissions: any): boolean {
    try {
      let tokenInfo = this.getInfo();
      if (!tokenInfo) throw {};
      let matchs = permissions.map((permission: string) =>
        (tokenInfo as any).permissions.includes(permission)
      );
      return matchs.some((match: boolean) => match === true);
    } catch (error) {
      return false;
    }
  }

  clearAll() {
    this._localStorageSrc.clear();
  }
}

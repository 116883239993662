import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { LoginService } from '@services/login.service';
import { MenuListComponent } from 'src/app/shared/components/menu-list/menu-list.component';

@Component({
  selector: 'tech-toolbar',
  standalone: true,
  imports: [CommonModule, MenuListComponent],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
  private _authSrc: AuthService = inject(AuthService);
  private _routerSrc: Router = inject(Router);
  private _loginSrc: LoginService = inject(LoginService);

  public principalMenu: any[] = [
    {
      icon: 'logout',
      label: 'Salir',
      type: 'btn',
      routerLink: '',
      onClick: this.logout.bind(this),
    },
  ];

  logout() {
    this._loginSrc.logout();
  }
}

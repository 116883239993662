import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

export const withTokenGuard: CanActivateFn = (
  route,
  state,
  _authSrc: AuthService = inject(AuthService),
  _routerSrc: Router = inject(Router)
) => {
  const tokenInfo = _authSrc.getInfo();

  if (!tokenInfo) {
    _authSrc.clearAll();
    _routerSrc.navigate(['/auth']);
    return false;
  }

  const { exp = 0 } = tokenInfo;
  const isExpired = new Date(0);
  isExpired.setUTCSeconds(exp);

  if (
    new Date() > isExpired
    // || !_authSrc.haveAnyPermission('')
  ) {
    _authSrc.clearAll();
    _routerSrc.navigate(['/auth']);
    return false;
  }

  return true;
};

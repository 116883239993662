import {
  AfterViewInit,
  Component,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RouterModule,
} from '@angular/router';
import { Subject, takeUntil, filter } from 'rxjs';
import { ContentComponent } from '@layoutComponents/content/content.component';
import { CustomizeService } from '@services/customize.service';

@Component({
  selector: 'tech-auth-layout',
  standalone: true,
  imports: [ContentComponent, CommonModule, RouterModule],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss',
})
export class AuthLayoutComponent implements AfterViewInit {
  public routerSrc: Router = inject(Router);
  public customizeSrc: CustomizeService = inject(CustomizeService);
  public authTitle = signal('');
  public authDescription = signal('');
  public authImgPath = signal('');

  private _allSuscription: Subject<boolean> = new Subject<boolean>();

  ngAfterViewInit(): void {
    this.watchRoute();
    this.routerSrc.events
      .pipe(
        takeUntil(this._allSuscription),
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        this.watchRoute();
      });
  }

  watchRoute() {
    switch (true) {
      case this.routerSrc.url.includes('auth/login'):
        this.buildView('authLogin');
        break;
      case this.routerSrc.url.includes('auth/recover-password'):
        this.buildView('authRecover');

        break;
      case this.routerSrc.url.includes('auth/change-password'):
        this.buildView('authChange');

        break;
    }
  }

  buildView(view: 'authLogin' | 'authRecover' | 'authChange') {
    const { title, subtitle, imgPath, onlyImg } =
      CustomizeService.data.text[view];

    // console.log({
    //   title,
    //   subtitle,
    //   imgPath,
    //   onlyImg,
    // });

    if (!onlyImg) {
      this.setAuthTitle(title);
      this.updateAuthDescription(subtitle);
    }

    if (imgPath) {
      this.updateAuthImgPath(imgPath);
    }

    // console.log(this.authTitle());
  }

  private setAuthTitle(title: string) {
    this.authTitle.set(title);
  }

  private updateAuthDescription(description: string) {
    this.authDescription.set(description);
  }

  private updateAuthImgPath(path: string) {
    this.authImgPath.set(path);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  notifications: {
    id?: string;
    type: 'success' | 'error' | 'warning' | 'info';
    title?: string;
    msg?: string;
    duration?: number;
  }[] = [];
  $notifications = new BehaviorSubject(this.notifications);

  addNotification({
    type = 'success',
    title = '',
    msg = '',
    duration = 3000,
  }: {
    type?: 'success' | 'error' | 'warning' | 'info';
    title?: string;
    msg?: string;
    duration?: number;
  }) {
    const config = {
      id: v4(),
      type,
      title,
      msg,
      duration,
    };

    this.notifications.push(config);
    this.$notifications.next(this.notifications);
    setTimeout(() => {
      this.removeNotification(config.id);
    }, config.duration);
  }

  removeNotification(id) {
    const index = this.notifications.findIndex((n) => n.id === id);
    this.notifications.splice(index, 1);
    this.$notifications.next(this.notifications);
  }
}

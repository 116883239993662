<div class="menu-global" #boxPrimary>
  <button class="menu-global__trigger" (click)="toggleVisibility()">
    <ng-content></ng-content>
  </button>
  <div
    class="menu-global__wrapper"
    [ngStyle]="{ '--x': coorX + 'px', '--y': coorY + 'px' }"
    [ngClass]="{ visible: isVisible }"
  >
    <ul class="menu-global__list">
      @for (item of children; track $index) {
      <li class="menu-global__list__item">
        @switch (item.type) { @case ('btn') {
        <button
          class="menu-global__list__item__trigger"
          (click)="item?.onClick?.(); toggleVisibility()"
        >
          <div></div>
          <p>{{ item.label }}</p>
        </button>

        } @case ('link') {
        <a
          [routerLink]="item.routerLink"
          class="menu-global__list__item__trigger"
          (click)="toggleVisibility()"
        >
          <div></div>
          <p>{{ item.label }}</p>
        </a>

        } }
      </li>
      }
    </ul>
  </div>
</div>

import { Component } from '@angular/core';
import { AuthLayoutComponent } from '../layouts/styles/auth-layout/auth-layout.component';

@Component({
  selector: 'tech-auth',
  standalone: true,
  imports: [AuthLayoutComponent],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent {}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CustomizeService } from '@services/customize.service';
import { MenuListComponent } from 'src/app/shared/components/menu-list/menu-list.component';

@Component({
  selector: 'tech-navbar',
  standalone: true,
  imports: [CommonModule, MenuListComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  public modules = CustomizeService.data.modules;
}

import { Component } from '@angular/core';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { AsideComponent } from '../../components/aside/aside.component';
import { ContentComponent } from '../../components/content/content.component';
import { NavbarComponent } from '@layoutComponents/navbar/navbar.component';

@Component({
  selector: 'tech-general-layout',
  standalone: true,
  imports: [
    ToolbarComponent,
    AsideComponent,
    ContentComponent,
    NavbarComponent,
  ],
  templateUrl: './general-layout.component.html',
  styleUrl: './general-layout.component.scss',
})
export class GeneralLayoutComponent {}

export const getIpPublic = async () => {
  try {
    const { ip } = await fetch('https://api.ipify.org?format=json').then(
      (response) => response.json()
    );
    return ip;
  } catch (error) {
    return '0.0.0.0';
  }
};

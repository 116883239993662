import { Component } from '@angular/core';
import { GeneralLayoutComponent } from '../layouts/styles/general-layout/general-layout.component';

@Component({
  selector: 'tech-main',
  standalone: true,
  imports: [GeneralLayoutComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent {}

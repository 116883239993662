<div class="banner_erp">
  <div class="banner_erp__overlay">
    @if (authTitle()) {
    <p class="banner_erp__title">{{ authTitle() }}</p>
    } @if (authDescription()) {

    <p class="banner_erp__description">{{ authDescription() }}</p>
    } @if (authImgPath()) {
    <img [src]="authImgPath()" alt="logo_banner_erp" />

    }
  </div>
</div>
<div>
  <router-outlet></router-outlet>
</div>

import { Injectable, inject } from '@angular/core';
import { TechpreNatsService } from './techpre-nats.service';
import { CustomizeService } from './customize.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _authSrc: AuthService = inject(AuthService);
  private _routerSrc: Router = inject(Router);
  public techpreNatsSrc: TechpreNatsService = inject(TechpreNatsService);
  public custimizeSrc: CustomizeService = inject(CustomizeService);
  async signIn({ email, password }: { email: string; password: string }) {
    return await this.techpreNatsSrc
      .sendRequest({
        key: 'auth.login',
        payload: { domain: CustomizeService.data.domain, email, password },
      })
      .then((response) => {
        if (response.status === 'success' && response.token) {
          this._authSrc.setToken(response.token);
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  async recoverPassword({ email }: { email: string }) {
    return await this.techpreNatsSrc.sendRequest({
      key: 'auth.recoveryPassword',
      payload: { domain: CustomizeService.data.domain, email },
    });
  }
  async changePassword({
    password,
    token,
  }: {
    password: string;
    token: string;
  }) {
    return await this.techpreNatsSrc.sendRequest({
      key: 'auth.changePassword',
      payload: { domain: CustomizeService.data.domain, password, token },
    });
  }

  async logout() {
    this._authSrc.clearAll();
    this._routerSrc.navigate(['/auth/login']);
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

export const withoutTokenGuard: CanActivateFn = (
  route,
  state,
  _authSrc: AuthService = inject(AuthService),
  _routerSrc: Router = inject(Router)
) => {
  const isValid = _authSrc.getToken() ? false : true;

  if (!isValid) _routerSrc.navigate(['/dashboard']);

  return isValid;
};
